import store from '@/store'
import axios from 'axios'
// import router from '@/router/'

export default {
  namespaced: true,
  state: {
    files: [],
    images: [],
    pdf: [],
    error: null
  },
  mutations: {
    set_accion_files (state, files) {
      state.files = files
    },
    set_accion_error (state, error) {
      state.error = error
    }
  },
  actions: {
    async listFiles ({ commit, dispatch, state }, prefix) {
      try {
        const response = await axios({
          method: 'get',
          url: `${store.state.hostAws}/files-manager/api/files-manager/list?prefix=${prefix}`,
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api listFiles')
          // console.log(response.data)
          commit('set_accion_files', response.data)
          return true
        } else {
          console.log('por else listFiles')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch listFiles')
        commit('set_accion_error', err)
        // router.push({ path: '/' })
        return true
      }
    },
    async uploadFile ({ commit }, request) {
      console.log('uploadFile')
      // console.log(request.path)
      try {
        // Obtener el archivo como ArrayBuffer
        const arrayBuffer = request.file
        // Enviar el archivo binario y el path como parámetro de consulta
        const response = await axios.post(`${store.state.hostAws}/files-manager/api/files-manager/create?path=${encodeURIComponent(request.path)}`, arrayBuffer, {
          headers: {
            Authorization: 'Bearer ' + store.state.token,
            'Content-Type': 'application/octet-stream', // Tipo binario
            'x-file-name': request.name
          }
        })
        if (response.status === 200) {
          console.log('✅ Archivo subido correctamente')
          return response.data
        }
        return false
      } catch (err) {
        console.error('❌ Error en uploadFile:', err)
        commit('set_accion_error', err)
        return false
      }
    },
    async deleteFile ({ commit, state }, filePath) {
      try {
        const response = await axios.delete(
          `${store.state.hostAws}/files-manager/api/files-manager/delete?fileName=${encodeURIComponent(filePath)}`,
          {
            headers: {
              Authorization: 'Bearer ' + store.state.token
            }
          }
        )
        if (response.status === 200) {
          console.log('✅ Archivo eliminado correctamente')
          // Remueve el archivo de la lista de archivos en el estado
          commit('set_accion_files', state.files.filter(file => file !== filePath))
          return true
        }
        return false
      } catch (err) {
        console.error('❌ Error en deleteFile:', err)
        commit('set_accion_error', err)
        return false
      }
    }
  },
  getters: {
  }
}
