import { mapActions, mapState } from 'vuex'
import ImagenesView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Visitas_cliente/Imagenes/ImagenesView.vue'
import EditarFieldView from '@/components/Layout/Menu/AdministradorFlotaNoProgramado/Editar_transporte/Editar_field/EditarFieldView.vue'

export default {
  name: 'FilesSiniestrosView',
  components: {
    ImagenesView,
    EditarFieldView
  },
  props: {
    data: null
  },
  data: function () {
    return {
      dataJSON: [],
      selectedFile: null,
      uploadMessage: '',
      path: '',
      // s3BaseUrl: 'https://dmetrix-files.s3.us-east-1.amazonaws.com/',
      spinner: false
    }
  },
  watch: {
  },
  mounted () {
    this.dataJSON = JSON.parse(this.data)
    this.path = 'vps/V08/' + this.dataJSON.id
    this.getFiles()
  },
  methods: {
    ...mapActions('FileManager', [
      'uploadFile', 'listFiles', 'deleteFile'
    ]),
    onFileSelected (event) {
      this.selectedFile = event.target.files[0]
    },
    async getFiles () {
      await this.listFiles(this.path)
    },
    async createFile () {
      if (!this.selectedFile) return

      // Convertir el archivo a ArrayBuffer
      const arrayBuffer = await this.selectedFile.arrayBuffer()

      // Hacer la solicitud para subir el archivo en binario
      const request = {
        path: this.path,
        file: arrayBuffer,
        name: this.selectedFile.name
      }
      this.spinner = true
      const response = await this.uploadFile(request)
      if (response) {
        this.makeToast({
          title: 'Éxito',
          body: '✅ Archivo almacenado correctamente.',
          variant: 'success'
        })
        this.getFiles() // Actualiza la lista después de eliminar
      } else {
        this.makeToast({
          title: 'Error',
          body: '❌ No se pudo almacenado el archivo. Intenta de nuevo.',
          variant: 'danger'
        })
      }
      this.spinner = false
    },
    getFileName (filePath) {
      return filePath.replace(this.path + '/', '') // Elimina el prefijo dinámico
    },
    downloadFile (filePath) {
      const fileUrl = this.endpointS3 + filePath // Construye la URL completa

      // Crear un enlace temporal y disparar la descarga
      const link = document.createElement('a')
      link.href = fileUrl
      link.download = this.getFileName(filePath)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    async deleteFilePath (filePath) {
      try {
        this.spinner = true
        const success = await this.deleteFile(filePath)
        if (success) {
          this.makeToast({
            title: 'Éxito',
            body: '✅ Archivo eliminado correctamente.',
            variant: 'success'
          })
          this.getFiles() // Actualiza la lista después de eliminar
        } else {
          this.makeToast({
            title: 'Error',
            body: '❌ No se pudo eliminar el archivo. Intenta de nuevo.',
            variant: 'danger'
          })
        }
      } catch (error) {
        console.error('❌ Error al eliminar el archivo:', error)
        this.makeToast({
          title: 'Error',
          body: '❌ Ocurrió un error al intentar eliminar el archivo.',
          variant: 'danger'
        })
      }
      this.spinner = false
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('FileManager', ['files']),
    ...mapState(['endpointS3'])
  }
}
